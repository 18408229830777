import { firstValueFrom } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCRUDApiClient } from '@do/app-base-components';
import { SKIP_INTERCEPT_HEADER } from '@do/app-error';
import { SeatControlUnitDto } from '@do/common-dto';

import { ApiClientConfig } from '../api-client-config';

@Injectable({ providedIn: 'root' })
export class SeatControlUnitApiClient extends BaseCRUDApiClient<SeatControlUnitDto> {
  baseUrl = this.config.apiEndpoint + '/seat-control-unit';

  constructor(http: HttpClient, private config: ApiClientConfig) {
    super(http);
  }

  resetErrorPromise(serial: string, masterControlUnitId: string) {
    return firstValueFrom(
      this.http.put<SeatControlUnitDto>(`${this.baseUrl}/reset-error`, {
        serial,
        masterControlUnitId,
      })
    );
  }

  updateSeatNumberPromise(
    serial: string,
    masterControlUnitId: string,
    row: string | null,
    seat: number | null,
    overridePosition: boolean
  ) {
    return firstValueFrom(
      this.http.put<SeatControlUnitDto>(
        `${this.baseUrl}/update-seat-number`,
        {
          serial,
          masterControlUnitId,
          row,
          seat,
          overridePosition,
        },
        {
          headers: new HttpHeaders().set(SKIP_INTERCEPT_HEADER, '1'),
        }
      )
    );
  }

  updateSeatRepeaterMappedIndexPromise(
    serial: string,
    masterControlUnitId: string,
    repeaterMappedIndex: number | null
  ) {
    return firstValueFrom(
      this.http.put<SeatControlUnitDto>(
        `${this.baseUrl}/update-seat-repeater-mapped-index`,
        {
          serial,
          masterControlUnitId,
          repeaterMappedIndex,
        }
      )
    );
  }
}
